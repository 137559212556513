import { gsap } from 'gsap'

import { reinitializeAlpine } from './alpine-init'
import { initializeHeadroom } from './headroom-init'

// Configuration constants
const config = {
	animation: {
		duration: {
			background: 0.5,
			logo: 0.5,
			contentFade: 0.5,
			hero: 1,
		},
		delay: {
			alpineInit: 100,
			scrollEvent: 100,
		},
		offset: {
			y: 24,
		},
	},
	selectors: {
		preloader: '#preloader',
		siteContent: '#site-content',
		header: '.header',
		footer: '.footer',
		heroElements: '.hero__tagline, .hero__heading, .hero__subheading',
		intersectElements: '[x-intersect]',
		preloaderBg: '.preloader__background',
		heroImage: '.hero__image',
	},
}

/**
 * Reset element states using GSAP
 * @param {NodeList|Element} elements - Elements to reset
 * @param {boolean} [isHero=false] - Whether the elements are hero elements
 */
function resetElementStates(elements, isHero = false) {
	const defaultProps = { opacity: 0, y: config.animation.offset.y }

	if (elements instanceof NodeList) {
		elements.forEach((el) => {
			if (isHero) gsap.set(el, { clearProps: 'all' })
			gsap.set(el, defaultProps)
		})
	} else if (elements) {
		gsap.set(elements, { clearProps: 'all' })
	}
}

/**
 * Animate hero elements
 * @param {NodeList} heroElements - Hero elements to animate
 */
function animateHeroElements(heroElements) {
	heroElements.forEach((el) => {
		gsap.to(el, {
			opacity: 1,
			y: 0,
			duration: config.animation.duration.hero,
			ease: 'power3.out',
		})
	})
}

/**
 * Initialize Alpine.js and trigger scroll event
 */
function initializeAlpineAndScroll() {
	setTimeout(() => {
		reinitializeAlpine()
		setTimeout(() => {
			window.dispatchEvent(new Event('scroll'))
		}, config.animation.delay.scrollEvent)
	}, config.animation.delay.alpineInit)
}

/**
 * Handle post-preloader tasks
 * @param {Function} [callback] - Optional callback to execute
 */
function handlePreloaderComplete(callback) {
	const elements = {
		preloader: document.querySelector(config.selectors.preloader),
		content: document.querySelector(config.selectors.siteContent),
		header: document.querySelector(config.selectors.header),
		footer: document.querySelector(config.selectors.footer),
		heroElements: document.querySelectorAll(config.selectors.heroElements),
		intersectElements: document.querySelectorAll(config.selectors.intersectElements),
	}

	// Show main content
	elements.content?.classList.remove('content__main--hidden')
	elements.header?.classList.add('headroom--pinned')

	// Execute callback if provided
	if (typeof callback === 'function') callback()

	// Hide preloader
	if (elements.preloader) {
		elements.preloader.style.display = 'none'
	}

	// Initialize components
	initializeHeadroom()
	elements.footer?.classList.remove('hidden')

	// Reset and animate elements
	resetElementStates(elements.footer)
	resetElementStates(elements.intersectElements)
	resetElementStates(elements.heroElements, true)
	animateHeroElements(elements.heroElements)

	// Initialize Alpine.js and trigger scroll
	initializeAlpineAndScroll()
}

/**
 * Create and execute preloader animation timeline
 * @param {Element} background - Preloader background element
 * @param {Element} logo - Preloader logo element
 * @param {Element} siteContent - Main site content element
 * @param {Function} callback - Completion callback
 */
function createPreloaderTimeline(background, logo, siteContent, callback) {
	const tl = gsap.timeline({
		onComplete: () => {
			gsap.to(siteContent, {
				autoAlpha: 1,
				duration: config.animation.duration.contentFade,
				onComplete: () => {
					handlePreloaderComplete(callback)
					sessionStorage.setItem('preloader', 'true')
				},
			})
		},
	})

	return tl
		.to(
			background,
			{
				scaleY: 1,
				duration: config.animation.duration.background,
				ease: 'power2.out',
			},
			0,
		)
		.to(
			logo,
			{
				yPercent: 0,
				autoAlpha: 1,
				duration: config.animation.duration.logo,
				ease: 'power2.out',
			},
			0.5,
		)
		.to(
			logo,
			{
				yPercent: -100,
				autoAlpha: 0,
				duration: config.animation.duration.logo,
				ease: 'power2.in',
			},
			1.5,
		)
		.to(
			background,
			{
				scaleY: 0,
				transformOrigin: 'top',
				duration: config.animation.duration.background,
				ease: 'power2.in',
			},
			2,
		)
}

/**
 * Initialize the preloader
 * @param {Function} [callback] - Optional callback to execute after preloader completes
 */
export function initializePreloader(callback) {
	const elements = {
		preloader: document.querySelector(config.selectors.preloader),
		content: document.querySelector(config.selectors.siteContent),
		background: document.querySelector(`${config.selectors.preloader} ${config.selectors.preloaderBg}`),
		logo: document.querySelector(`${config.selectors.preloader} svg`),
		footer: document.querySelector(config.selectors.footer),
	}

	if (!elements.preloader || !elements.content) return

	if (sessionStorage.getItem('preloader')) {
		gsap.set(elements.preloader, { display: 'none' })
		handlePreloaderComplete(callback)
		return
	}

	elements.preloader.style.display = 'flex'
	elements.logo?.classList.remove('invisible')

	if (!elements.background || !elements.logo) return

	// Set initial states
	gsap.set(elements.footer, { display: 'none' })
	gsap.set(elements.logo, { yPercent: 100, autoAlpha: 0 })
	gsap.set(elements.content, { autoAlpha: 0 })

	// Create and execute animation timeline
	createPreloaderTimeline(elements.background, elements.logo, elements.content, callback)
}
